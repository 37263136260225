import objectFitVideos from 'object-fit-videos';
import whenDomReady from 'when-dom-ready';
function initialise() {
    if ('objectFit' in document.documentElement.style === false) {
        const someImages = Array.from(document.querySelectorAll('.js-video'));
        if (someImages && someImages.length) {
            setTimeout(() => {
                objectFitVideos(someImages, { watchMQ: true });
            }, 100);
        }
    }
}
whenDomReady().then(initialise);
