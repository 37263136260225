import objectFitImages from 'object-fit-images';
import whenDomReady from 'when-dom-ready';
function initialise() {
    if ('objectFit' in document.documentElement.style === false) {
        const someImages = Array.from(document.querySelectorAll('.js-object-fit'));
        if (someImages && someImages.length) {
            setTimeout(() => {
                objectFitImages(someImages, { watchMQ: true });
            }, 100);
        }
    }
}
whenDomReady().then(initialise);
